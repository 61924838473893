#nprogress .bar {
  background: #fff;
  height: 5px;
  border-radius: 5px;
}

#nprogress .peg {
  box-shadow: 0 0 20px #fff, 0 0 20px #fff;
}

#nprogress .spinner-icon {
  display: none;
}
