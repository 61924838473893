@import url(https://fonts.googleapis.com/css?family=Nunito&display=swap);
#nprogress .bar {
  background: #fff;
  height: 5px;
  border-radius: 5px;
}

#nprogress .peg {
  box-shadow: 0 0 20px #fff, 0 0 20px #fff;
}

#nprogress .spinner-icon {
  display: none;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  font-family: "Nunito", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  box-sizing: border-box;
}

